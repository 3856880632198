import * as Sentry from '@sentry/browser';
import Vue from 'vue';

const isProduction = process.env.VUE_APP_ENV === 'production';

Sentry.init({
  dsn: 'https://20be730b19aa4f3597d62d164f608cd4@sentry.io/1874034',
  integrations: isProduction ? [new Sentry.Integrations.Vue({ Vue })] : [],
  beforeSend(event) {
    return isProduction ? event : null;
  },
});
