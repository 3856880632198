<template>
  <div
    id="galeria"
    class="mb-12"
  >
    <div class="text-center display-3 mt-12 mb-4">
      <span class="fontDancingScript">
        Galéria
      </span>
    </div>

    <div class="d-flex flex-wrap justify-center">
      <div class="slideshow">
        <splide
          ref="primary"
          :options="primaryOptions"
        >
          <splide-slide
            v-for="slide in slides"
            :key="slide.src"
          >
            <img
              :src="slide.src"
              class="custom-image"
            >
          </splide-slide>

          <template v-slot:controls>
            <div class="splide__progress">
              <div class="splide__progress__bar" />
            </div>
          </template>
        </splide>
        <br>
        <splide
          ref="secondary"
          :options="secondaryOptions"
        >
          <splide-slide
            v-for="slide in slides"
            :key="slide.src"
          >
            <img :src="slide.src">
          </splide-slide>
        </splide>
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';

const imageNames = require
  .context('../../public/img/gallery/', true, /\.(jpg)$/)
  .keys()
  .map(imagePath => imagePath.split('/').pop());

function makeSourceObject(imageName) {
  return { src: `/img/gallery/${imageName}`, thumbnail: `/img/gallery/${imageName}` };
}

const imageSources = imageNames.map(imageName => makeSourceObject(imageName));

export default {
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      primaryOptions: {
        type: 'loop',
        autoWidth: true,
        autoHeight: true,
        focus: 'center',
        pagination: false,
        rewind: false,
        perPage: 1,
        gap: '1rem',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: true,
        arrows: false,
      },
      secondaryOptions: {
        type: 'slide',
        rewind: true,
        gap: '1rem',
        pagination: false,
        fixedWidth: 110,
        fixedHeight: 70,
        cover: true,
        focus: 'center',
        isNavigation: true,
      },
      slides: imageSources,
      count: 0,
    };
  },
  mounted() {
    // Set the sync target.
    this.$refs.primary.sync(this.$refs.secondary.splide);
  },
};
</script>

<style scoped>
.slideshow {
  max-width: 750px;
}

.custom-image {
  height: 500px;
}

@media only screen and (max-width: 800px) {
  .slideshow {
    max-width: 400px;
  }
  .custom-image {
    max-width: 400px;
    max-height: 280px;
  }
}
</style>
