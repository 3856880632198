<template>
  <VFooter height="400" color="primary" class="pa-0">
    <VRow align="center" no-gutters>
      <VCol cols="12">
        <div class="d-flex justify-center">
          <a
            href="https://www.facebook.com/janos.dunai.94"
            target="_blank"
            style="width: 55px"
            class="mr-2"
          >
            <VImg :src="require('@/assets/fb.svg')" contain eager width="55px" />
          </a>

          <a
            href="https://www.instagram.com/dunai.janos_official/"
            target="_blank"
            style="width: 55px"
            class="mx-2"
          >
            <VImg :src="require('@/assets/instagram.svg')" contain eager width="55px" />
          </a>

          <a
            href="https://www.tiktok.com/@dunai.janos_official"
            target="_blank"
            style="width: 55px"
            class="mx-2"
          >
            <VImg :src="require('@/assets/tiktok.svg')" contain eager width="55px" />
          </a>

          <a
            href="https://www.threads.net/@dunai.janos_official"
            target="_blank"
            style="width: 55px"
            class="ml-2"
          >
            <VImg :src="require('@/assets/threads.svg')" contain eager width="55px" />
          </a>
        </div>

        <RouterLink :to="{ name: 'Home' }">
          <VImg
            :src="require('@/assets/logo-white.svg')"
            contain
            eager
            width="320px"
            max-width="80vw"
            class="mx-auto px-0 mt-5"
            @click="scrollTop"
          />
        </RouterLink>
      </VCol>

      <VCol
        class="text-center subtitle-2 font-weight-bold white--text"
        cols="12"
        style="padding-top: 60px"
      >
        <div class="text-xs-center">
          Made with ❤ by
          <a href="https://impressia.hu/" target="_blank" class="white--text"> Impressia </a>
        </div>

        <div>© {{ new Date().getFullYear() }} Dunai János</div>

        <div class="px-2">
          <RouterLink to="/#kapcsolat" class="white--text"> Kapcsolat </RouterLink>
          |
          <RouterLink :to="{ name: 'PrivacyStatement' }" class="white--text">
            Adatvédelmi nyilatkozat
          </RouterLink>
          |
          <RouterLink :to="{ name: 'LegalNotice' }" class="white--text">
            Jogi nyilatkozat
          </RouterLink>
        </div>
      </VCol>
    </VRow>
  </VFooter>
</template>

<script>
export default {
  name: 'ThePageFooter',
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped></style>
