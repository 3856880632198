<template>
  <div>
    <!-- eslint-disable -->
    <div id="fb-root"></div>

    <!-- Your Chatmodul code -->
    <div id="fb-customer-chat" class="fb-customerchat"></div>
     <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'ThePageFbChat',
  mounted() {
    /* eslint-disable */
    var chatbox = document.getElementById('fb-customer-chat');
    chatbox.setAttribute("page_id", "108232108440553");
    chatbox.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function() {
      FB.init({
        xfbml: true,
        version: 'v15.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/hu_HU/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    /* eslint-enable */
  },
};
</script>

<style scoped>
</style>
