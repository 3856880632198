<template>
  <div id="rolam-mondtak" class="mb-12">
    <div class="text-center display-3 mt-12 mb-4">
      <span class="fontDancingScript"> Rólam mondták </span>
    </div>

    <div class="mx-auto" style="max-width: 1385px">
      <VSlideGroup
        class="slideGroup px-0 px-sm-4"
        show-arrows
        @click:next="closeWeddingItems"
        @click:prev="closeWeddingItems"
      >
        <TheHomeWeddingsItem
          title="Marietta&amp;amp;Norbi (Békéscsaba)"
          sub-title='"...tökéletessé tette a Nagy Napunkat!"'
          image-name="marietta-norbi.jpg"
        >
          <template slot="comment">
            „Ezzel a pár képpel szerettük volna megköszönni a munkádat, az eseményhez való
            hozzáállásodat, a rugalmasságodat, a jókedvet, a profizmust és mindent ami ilyen
            tökétessé tette a Nagy Napunkat!<br />
            Mindenkitől csak a dicséretet kaptuk :) és nekünk ez volt a legfontosabb!<br />
            Még egyszer nagyon szépen köszönjük, és további sok-sok sikert ebben a szakmában Neked!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Bea&amp;Csabi (Balatonlelle)"
          sub-title='"...legjobb döntésünk volt, hogy felkértünk Téged"'
          image-name="bea-csabi.jpg"
        >
          <template slot="comment">
            ”Kedves Jani!<br />
            Bár kezdetben nem szerettünk volna az esküvőnkre vőfélyt, utólag visszagondolva a
            legjobb döntésünk volt, hogy mégis felkértünk Téged.<br />
            Tökéletes keretet adtál a lakodalmunknak, amiért nagyon hálásak vagyunk ezúton is.<br />
            Kezdve ott, hogy megbeszéltük a programtervet és jó tanácsokat adtál nekünk, azon át,
            hogy a Mamámnak könnybelábadt a szeme, mikor megemlítetted a rétesét, addig, hogy óriási
            bulit csaptál a lakodalomban, mindent végtelen profizmusod kísért.<br />
            Szeretettel ajánlunk minden leendő házaspárnak, mert biztosak vagyunk benne, hogy nem
            fognak csalódni!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Adri&amp;Krisz (Kecskemét)"
          sub-title='"...felülmúltad az összes elképzelésünket"'
          image-name="adri-krisztian.jpg"
        >
          <template slot="comment">
            ”Kedves János!<br />
            Szeretnénk megköszönni, hogy már az első találkozásunk után rögtön úgy érezhettük, hogy
            a Nagy napunkon minden rendben lesz, mert láttuk, hogy nagyon rutinosan kezeled a
            programokat. Pontosan a megbeszéltek szerint történt minden, és számunkra a legjobb
            érzés az volt, amikor nyugtatgattál minket, hogy nem lesz semmi gond.<br />
            És így is lett!<br />
            Mindent úgy alakítottál, hogy nekünk és a vendégeknek is jó legyen. Mindenkitől pozitív
            volt a visszajelzés. Nem sok esküvőben voltunk, de Te felülmúltad az összes
            elképzelésünket, amit egy Vőfély teljesíthet. Minden játékhoz voltak eszközeid,
            vonatozáshoz vasutas sapka, a limbóhoz léc, majd a nyertesnek korona. Mind nagyon sokat
            dobott a hangulaton. Nagyon köszönjük, hogy végig mellettünk álltál, segítettél, és hogy
            mindent megtettél azért, hogy tökéletes legyen a napunk. Örökre emlékezni fogunk erre a
            napra, amiben nagy szereped volt és van is, mivel amikor visszagondolunk, mindig
            emlegettünk, hogy milyen jó bulit csináltál.<br />
            Nagyon köszönjük!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Anett&amp;Pisti (Gyomaendrőd)"
          sub-title='"...kedves, jó humorú, szórakoztató"'
          image-name="anett-pisti.jpg"
        >
          <template slot="comment">
            „Kedves János!<br />
            A mi találkozásunk egy véletlen folytán adódott, de ha már így hozta Sors, egyáltalán
            nem bántuk. Minden pillanatát élveztük a Nagy Napnak, minden úgy zajlott, ahogy azt
            előre megbeszéltük. Kedves, jó humorú, szórakoztató, mindenkivel megtalálta a közös
            hangot. A bulink szuper volt, a násznép visszajelzése is csak pozitív. Minden párnak,
            akik a jövőben kelnek egybe, csak ajánlani tudjuk Jánost, aki szebbé teszi azt a
            csodálatos napot. Tiszta szívből köszönjük Neked ezt a napot, melyről Rád is örökké
            emlékezni fogunk.”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Hédi&amp;Viktor (Piliscsév)"
          sub-title='"...mert az a nap miattad is lett ennyire fantasztikus emlék"'
          image-name="hedi-viktor.jpg"
        >
          <template slot="comment">
            “Örülünk, hogy amikor gondolkodtunk a vőfélyeken anno, rád esett a választásunk!<br />
            Számunkra egy felejthetetlen élményt nyújtottál azzal ahogyan a Nagy Napunkon jelen
            voltál.<br />
            Kiválóan koordináltad az eseményeket, foglalkoztál külön-külön velünk is illetve a
            násznép tagjaival.<br />
            Alkalmazkodtál az itteni szokásokhoz is. Figyelembe vettél minden apró kérést,
            kívánságot, benne voltál minden fajta buliban illetve csináltad is azt. (Kocsis játék,
            pénz eső, vonatozás masiniszta kalapokban).<br />
            Ezen kívül meglepetés játékkal is készültél amiről még mi sem tudtunk (táncpárbaj).<br />
            Nagyon kiemelnénk, hogy a felszolgálóknak is nagy segítséget nyújtottál és maximálisan
            részt vettél abban is.Számodra ismeretlen szolgáltatókkal is megtaláltad a hangot és
            tudtál velük együtt dolgozni!<br />
            Igaz első alkalommal jártál nálunk Piliscséven vőfélyként, de úgy néz ki nem utoljára. A
            násznép pár tagja kijelentette, hogy ha nekik esküvőjük lesz gondolkodniuk sem kell hogy
            kit kérjenek fel a vőfély szerepre:)!<br />
            Végezetül nagyon szépen meg szeretnénk köszönni a munkádat és a rengeteg fáradozást amit
            nyújtottál, mert az a nap miattad is lett ennyire fantasztikus emlék számunkra.<br />
            Reméljük még találkozunk “vendégként” is veled a jövőben Piliscséven:)!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Nóri&amp;Danilo (Szajol)"
          sub-title='"...mindenre odafigyeltél"'
          image-name="nori-danilo.jpg"
        >
          <template slot="comment">
            „Az esküvőnk nagyon jól sikerült, pont olyan volt, amilyennek elképzeltem. Utólag is
            köszönöm a munkádat, jobb vőfélyt nem is találhattunk volna! :)<br />
            A vendégek is pozitívan nyilatkoztak Rólad, sőt az egyik barátnőm meg is jegyezte, hogy
            végre egy vőfély, aki megfelelően irányítja az esküvőt, jó humora van és a játékok is
            aranyosak.<br />
            Én is így éreztem, hogy mindenre odafigyeltél és nagyon jó hangulatú esküvőt tudhatunk
            magunk mögött.<br />
            Sok sikert kívánok a jövőben és még egyszer köszönjük a munkádat!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Niki&amp;Sebi (Gyula)"
          sub-title='"...olyan voltál, mintha lenne egy tündérkeresztapánk"'
          image-name="niki-sebi.jpg"
        >
          <template slot="comment">
            "Kedves Jani, ezzel a képpel szeretnénk kifejezni hálánkat neked, hogy az esküvőn
            levetted a vállunkról az összes stresszt. Kívánni se kívánhattunk volna jobb vőfélyt
            (olyan voltál számunkra a Nagy napon, mintha lett volna egy tündérkeresztapánk) Tiszta
            szívből ajánlunk téged bárkinek, mert aki téged választ, nem csak egy szolgáltatást kap
            hanem őszinte törődést, és profi vőfélyt!"
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Szimi&amp;Bence (Szarvas)"
          sub-title='"...jó vőfély az, aki nem csak az esküvőn, hanem az esküvőig kísér"'
          image-name="szimi-bence.jpg"
        >
          <template slot="comment">
            “Szeretnénk megköszönni neked, hogy az első perctől fogva a legnagyobb profizmussal és
            odaadással segítetted életünk egyik legszebb napját. Bármikor hívtunk, kérdeztünk,
            ötleteltünk mindig rugalmasan és segítőkészen álltál hozzánk. Az este és a szertartás is
            tökéletes volt, profin koordinálva és ezért mindig hálásak leszünk Neked. Bár már régóta
            ismerjük egymást, nem lehetett kérdés, hogy téged válasszunk és ez egy csodálatosan jó
            döntés volt. Szerintünk a jó vőfély az aki nem csak ez esküvőn, hanem az esküvőig kísér.
            Mi megtaláltuk a legjobbat.”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Blanka&amp;Zalán (Mórahalom)"
          sub-title='"…a legjobb dolog, ami történhetett velünk"'
          image-name="blanka-zalan.jpg"
        >
          <template slot="comment">
            ”Már az első találkozás alkalmával nagyon megfogott bennünket János. Az első közös
            beszélgetésünk elején kiderült számunkra megkérdőjelezhetetlen professzionalitása és
            tapasztalata. Esküvő előtt álló párként, izgulva és idegesen, megannyi feladattal és
            kérdéssel szembenézve, a legjobb dolog, ami történhetett velünk, az a Jánossal való
            beszélgetésünk volt. Mi még nem láttuk a fényt az alagút végén, de Jánosnak elmondva az
            elképzeléseinket az ő fejében már megszületett a forgatókönyv. Abban a pillanatban
            kialakult közöttünk a bizalom, a vőlegényemmel mindketten megnyugodtunk és éreztük, hogy
            János lesz az, akiben megbízunk, és akire a Nagy Napunkon teljes mértékben
            támaszkodhatunk. Az egyik legjobb döntésünk volt, hogy őt választottuk vőfélyünknek. A
            szervezés egész ideje alatt számíthattunk rá, végig tartottuk a kapcsolatot. Gyönyörű
            beszédeket mondott, figyelembe vette kéréseinket, kívánságainkat, rugalmas, humoros,
            vidám és egy csupaszív ember. Gondoskodott arról, hogy zökkenőmentes legyen a Nagy Nap
            és lelkitámaszt nyújtott végig. Saját kellékeivel hihetetlenül feldobta a bulit és a
            hangulatot, figyelt arra, hogy mindenki jól érezze magát, profin koordinálta a
            vendégeket és összefogta a szolgáltatókat. János az esküvőnk egyik alappillére volt,
            segített megvalósítani az álmainkat, amiért rendkívül hálásak vagyunk neki.
            <br />
            Köszönünk mindent!”<br />
            Blanka és Zalán
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Hanna&amp;Jani (Üllés)"
          sub-title='"…nekünk csak élvezni kellett a nagy napot"'
          image-name="hanna-jani.jpg"
        >
          <template slot="comment">
            “Nagy terhet vett le a vállunkról, hogy az első perctől kezdve kezében tartotta az
            irányítást, nekünk csak élvezni kellett a nagy napot. A szervezésben is rengeteget
            segített, fontos tanácsokkal látott el, melyekre mi nem is gondoltunk. Külön köszönjük a
            személyünkre szabott játékokat is. A 210 fős lagzi fergeteges hangulatához sokat
            hozzátett Jani szakmai profizmusa. Az ilyen nagy lakodalmakon előforduló ad-hoc
            kihívásokat is kezelni tudta. Bizalommal ajánljuk minden pár számára! Köszönünk Mindent!
            :)”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Dóri&amp;Zsolti (Gyomaendrőd)"
          sub-title='"...kézben tartottad az eseményeket"'
          image-name="dori-zsolti.jpg"
        >
          <template slot="comment">
            "Kedves János!<br />
            Az év vége közeledtével az ember mindig visszatekint arra, hogy mi is történt vele az
            elköszönő évben. Nos, azt hiszem, hogy velünk - közösen Veled - a lehető egyik legszebb
            dolog történt! Sor került a Nagy Napunkra!<br />
            Tudom, hogy mindent nagyon szépen megterveztem és mindent vagy ezerszer átbeszéltünk, de
            akkor ott nagyon, de nagyon izgultam! Ha Te nem lettél volna ott és nem tartottad volna
            kézben az eseményeket, biztos, hogy százszor infarktust kaptam volna és kicsúszott volna
            az irányítás a kezeim közül. De Te ott voltál! Ott voltál és figyeltél mindenre! Minden
            apró részletre, a szertartás menetére, éhesek vagyunk-e vagy szomjasak, melegünk van-e
            vagy éppen fázunk! Koordináltad a meglepetés vendégeket is (Kalapács József &amp; Rudán
            Joe-DJ), segítettél a későbbiekben Marcinak (a zenész-DJ) is! Remekül előkészítetted
            mindkét táncunkat, ami mindenkinek nagyon tetszett!<br />
            Szóval, Nélküled ez az szép Nap nem jöhetett volna létre! ...<br />
            A jövő évre is nagyon sok sikeres esküvőt, programokkal teli hétvégéket, erőt,
            egészséget és sok boldogságot kívánunk Neked!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Míra&amp;Matyi (Békéscsaba)"
          sub-title='"...figyelmes, segítőkész, egy szóval profi"'
          image-name="mira-matyi.jpg"
        >
          <template slot="comment">
            "Lehet elcsépelten hangzik, de valóban életünk legszebb napja volt június 19-e. Ehhez
            nagyban hozzájárult János személye, hiszen felkészült volt, figyelmes és segítőkész, egy
            szóval profi. Talán nem is tudjuk pontosan, hogy mennyit dolgozott a háttérben annak
            érdekében, hogy minden gördülékenyen menjen. Az egész este folyamán figyelt ránk és az
            volt neki a legfontosabb, hogy mi jól érezzük magunkat. A barátainkkal szövetkezve még
            egy meglepetéssel is készült nekünk. Nagyon jó döntés volt, hogy őt választottuk, hiszen
            egy remek embert ismertünk meg a személyében. :)"
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Angi&amp;Norbi (Mezőtúr)"
          sub-title='"…látszik rajta, hogy ez Neki nem munka, mert szeretettel csinálja"'
          image-name="angi-norbi.jpg"
        >
          <template slot="comment">
            “Egy vélemény a sok közül, ami Téged nagyon elismer Téged szerintem, hogy : "Nyilván ez
            a munkája, de látszik rajta, hogy ez Neki nem munka, mert szeretettel csinálja!" ...és
            tényleg!! Anyukám is teljesen oda volt Érted, meg szerintem Mindenki! Nagyon köszönjük,
            hogy jöttél, hogy itt voltál, hogy mindig szeretettel gondolunk Rád!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Anita&amp;Pisti (Békéscsaba)"
          sub-title='"...ha újra csinálnánk is téged választanánk"'
          image-name="anita-pisti.jpg"
        >
          <template slot="comment">
            “Kedves János!<br />
            Szeretnénk megköszönni neked, hogy az első találkozástól a nagy napunkig végig kísérted
            az életünket! Nem engedted el a kezünket, a legnagyobb izgalmakban is a legjobb
            támaszunk voltál! Nem kívánhatna nálad kedvesebb, ügyesebb, profibb vőfélyt senki! Ha
            újra csinálnánk is téged választanánk! Köszönjük neked!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Kata&amp;Viktor (Gyomaendrőd)"
          sub-title='"...miattad inkább áttesszük a nagy napunkat"'
          image-name="kata-viktor.jpg"
        >
          <template slot="comment">
            “Kedves Jani!<br />
            Szeretnénk ezzel a fotóval megköszönni, hogy levezetted a mi Nagy Napunkat. Számunkra
            nem csak szakember vagy, hanem egy nagybetűs ember és barát... Az első telefonhívásból
            TUDTAM, hogy miattad inkább áttesszük a nagy napunkat, mert nekem TE kellesz.... A
            találkozások alkalmával sem volt kérdés, hogy mindent megteszel majd értünk és ez így is
            volt. Minden vendégünktől azt a visszajelzést kaptuk, hogy ilyen kedves, segítőkész és
            szuper vőféllyel sosem találkoztak.... és hát mi is hálásak vagyunk Neked, hogy mindent
            levettél a vállunkról, mindig ott voltál, ahol szükség volt Rád! Csak ajánlani tudlak
            MINDENKINEK, és még egyszer köszönünk mindent....reméljük találkozunk még sokszor, akár
            lakodalomban is.”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Kati&amp;Zoli és Dodó (Gyula)"
          sub-title='"...remek ötleteid voltak"'
          image-name="kati-zoli.jpg"
        >
          <template slot="comment">
            „Az első megbeszélésen már leszűrtük, hogy hatalmas a felkészültséged, azzal, hogy egy
            komplett táblázattal érkeztél, ami aprólékosan kitért mindenre... A megbeszélésen is
            remek ötleteid voltak,amiket jól tudtunk használni. Az esküvő előtt egy nappal sms
            áradat érkezett tőled, hogy megnyugtass, illetve, hogy mik azok a fontos részletek,
            amiket el ne felejtsünk...ez is nagyon szimpatikus volt :)<br />
            Az esküvőn minden apró részletre figyeltél, profin vezényelted le és figyeltél minden
            részletre. A hangulat, az előadásaid is nagyon jók voltak, aprólékosan minden területet
            kézben tartottál, a vendégek visszacsatolása is ezt bizonyította. Hatalmas köszönet és
            örök hála, hogy életünk legszebb napja valóban gyönyörűre sikeredett és az esküvő
            levezénylési terhét viselted helyettünk...szépen irányítva, jó hangulatban :)<br />
            Egy idézet tőled: "csak egy kötelező elem van a nagy napra, az igen kimondása", ez a
            mondat sokat nyugtatott és ezt csak ajánlani tudom, hogy használd ezután is, ha
            bármelyikünk pánikolni kezdett, csak ezt a mondatot idéztük fel :)”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Fanni&amp;Tamás (Gyomaendrőd)"
          sub-title='"...tanítód, büszke lenne Rád"'
          image-name="fanni-tamas.jpg"
        >
          <template slot="comment">
            ”Kedves Jani!<br />
            A mi találkozásunkat a sors alakította így.<br />
            Két héttel a lagzi előtt kedves ismerősöktől kaptuk meg az elérhetőségedet bízva abban,
            hogy augusztus 1. napján szabad vagy.<br />
            A hozzáállásod, emberséged, figyelmességed, a buli hangulata, a barátaink összetartása
            igazán különlegessé tette a Nagy napunkat.<br />
            Bátran ki merem jelenteni, hogy tanítód, aki a legjobb tudása szerint vezetett be a
            vőfélység rejtelmeibe, most büszke lenne Rád!<br />
            Tiszta szívből ajánljuk Janit! Köszönjük szépen!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Enikő&amp;Ákos (Békéscsaba)"
          sub-title='"...bátran ajánlanálak téged "'
          image-name="eniko-akos.jpg"
        >
          <template slot="comment">
            „Teljes mértékben lelkiismeretes vagy, és érezhető, hogy maximális odaadással végzed a
            munkád. Fantasztikus beszédekkel tetted maradandó emlékké a családoktól való búcsúzást,
            és a lakodalomban is fergeteges hangulatot teremtettek az általad javasolt játékos
            feladatok, az ételek felkonferálása is tetszett. Ha bárki vőfélyt keres, nyugodt
            szívvel, bátran ajánlanánk téged és nagyon örülünk, hogy te voltál a vőfélyünk a nagy
            napunkon. ”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="Pap Péter, aPetit Catering - <br>Békéscsaba"
          image-name="apetit.jpg"
        >
          <template slot="comment"> „Jánossal és az Ő vezetésével jó dolgozni.” </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem
          title="NAIRAM Klubhotel &amp;<br> Szabadidőközpont - Szajol"
          image-name="nairam.jpg"
        >
          <template slot="comment">
            „Kedves János!<br />
            Nagyon szépen köszönjük!<br />
            Reméljük, hogy a későbbiekben több esküvőn is találkozhatunk veled!<br />
            További sikeres esküvőket kívánunk neked!”
          </template>
        </TheHomeWeddingsItem>

        <TheHomeWeddingsItem title="Arcanum Hotel**** - Békéscsaba" image-name="arcanum.jpg">
          <template slot="comment"> "Szuper vagy, Dunai János!" </template>
        </TheHomeWeddingsItem>
      </VSlideGroup>
    </div>
  </div>
</template>

<script>
import TheHomeWeddingsItem from '@/components/TheHomeWeddingsItem.vue';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'TheHomeWeddings',

  components: {
    TheHomeWeddingsItem,
  },

  methods: {
    closeWeddingItems() {
      eventBus.$emit('closeWeddingItems');
    },
  },
};
</script>

<style scoped>
.slideGroup >>> .v-slide-group__next {
  max-height: 580px;
}

.slideGroup >>> .v-slide-group__next i {
  font-size: 60px;
}

.slideGroup >>> .v-slide-group__prev {
  max-height: 580px;
}

.slideGroup >>> .v-slide-group__prev i {
  font-size: 60px;
}

@media (max-width: 450px) {
  .slideGroup >>> .v-slide-group__next {
    display: none;
  }

  .slideGroup >>> .v-slide-group__prev {
    display: none;
  }
}
</style>
