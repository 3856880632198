<template>
  <VRow
    align="center"
    justify="center"
    class="background mt-3"
  >
    <VCol
      class="text-center display-3"
      cols="12"
    >
      <span class="fontDancingScript argumentsTitle px-2">Plusz hét érv a jó döntés mellé...</span>
    </VCol>

    <VCol
      class="text-center"
      cols="12"
    >
      <VRow
        justify="center"
        class="argumentsRow"
      >
        <TheHomeArgumentsItem
          title="Nem iszom alkoholt."
          image-name="home-arguments-noalcohol.svg"
        >
          <template v-slot:tooltip>
            Ennek több oka is van; de legfőképpen az, hogy
            vőfélyként többször kell döntést hoznom, segítenem
            az eseményeket, koordinálni a teendőket, tartani a
            kapcsolatot a szolgáltatókkal (zenészekkel,
            videóssal, fotóssal, cateringgel), amely csak akkor
            megy professzionális módon, ha higgadtságomat
            nem befolyásolja semmi. Sem alkohol, sem drog.
          </template>
        </TheHomeArgumentsItem>

        <TheHomeArgumentsItem
          title="Nem alszom ott."
          image-name="home-arguments-nosleep.svg"
        >
          <template v-slot:tooltip>
            Ennek fő oka, hogy nem szeretnék extra költségbe
            kerülni nektek, nem indokolt. Amennyiben a
            távolság és a körülmények engedik, akkor
            hajnalban -dolgom végeztével- hazaindulok.
          </template>
        </TheHomeArgumentsItem>

        <TheHomeArgumentsItem
          title="Pontosan érkezem."
          image-name="home-arguments-nodelay.svg"
        >
          <template v-slot:tooltip>
            A lakodalom napján egy órával az események
            előtt már a helyszínen vagyok. Ezt szükségesnek látom
            azért, hogy minden érintett kollégával egyeztetni
            tudjak a forgatókönyv alapján.
          </template>
        </TheHomeArgumentsItem>

        <TheHomeArgumentsItem
          title="Minden kelléket biztosítok."
          image-name="home-arguments-todo.svg"
        >
          <template v-slot:tooltip>
            Azokat a kiegészítő apró, ámde fontos kellékeket is
            viszem, amelyek kellenek, avagy kellhetnek az
            esküvőtök alatt. Ilyenek például az esernyő, gyűrűpárna,
            díszdoboz az ajándékoknak, gyertya, kendő
            a játékokhoz, miegymás...
          </template>
        </TheHomeArgumentsItem>
      </VRow>

      <VRow
        justify="center"
        class="argumentsRow"
      >
        <TheHomeArgumentsItem
          title="Addig, nem csak akkor!"
          image-name="wedbook-ffffff.svg"
        >
          <template v-slot:tooltip>
            Bármikor kereshettek minden elérhetőségemen, ha kérdésetek felmerül. Addig vagyok
            vőfélyetek, nem csak akkor!
          </template>
        </TheHomeArgumentsItem>

        <TheHomeArgumentsItem
          title="Forgatókönyvet biztosítok!"
          image-name="calendar-ffffff.svg"
        >
          <template v-slot:tooltip>
            Amely sokat segít a lakodalom folyamatának megértésében.
          </template>
        </TheHomeArgumentsItem>

        <TheHomeArgumentsItem
          title="1/1!"
          image-name="fulltime-ffffff.svg"
        >
          <template v-slot:tooltip>
            Egy héten csak egy lakodalmat vállalok! Nem lehetek fáradt egy előző napi rendezvény
            okán. Mindenkinek maximumot szolgáltatok!
          </template>
        </TheHomeArgumentsItem>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import TheHomeArgumentsItem from '@/components/TheHomeArgumentsItem.vue';

export default {
  name: 'TheHomeArguments',
  components: {
    TheHomeArgumentsItem,
  },
};
</script>

<style scoped>
.background {
  background-image: url('../assets/home-arguments-parallax.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 600px;
}

.argumentsRow {
  margin: auto;
}

.splide--nav>.splide__track>.splide__list>.splide__slide.is-active {
  border-color: #795548 !important
}

@media (max-width: 1250px) {
  .argumentsRow {
    max-width: 650px !important;
  }
}

@media (max-width: 470px) {
  .argumentsTitle {
    font-size: 48px;
  }
}
</style>
