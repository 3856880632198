<template>
  <VSlideItem>
    <VCard
      class="ma-2"
      width="400px"
      max-width="67vw"
      color="white"
      style="border: thin solid rgba(0, 0, 0, 0.12) !important; height: fit-content"
    >
      <VImg v-if="imageName" :src="require(`@/assets/weddings/${imageName}`)" width="400px" eager />

      <VCardTitle
        class="px-4 primary--text font-weight-bold"
        style="word-break: break-word"
        v-html="title"
      />

      <VCardSubtitle
        v-if="subTitle"
        class="px-4 primary--text fontDancingScript"
        style="font-size: 18px"
        v-html="subTitle"
      />

      <VCardText class="px-4 black--text">
        <div ref="commentContainer" class="comment" :class="{ revealed }">
          <slot name="comment" />

          <div v-if="commentOverflow && !revealed" class="fadeLayer" />
        </div>
      </VCardText>

      <VIcon v-if="commentOverflow && !revealed" large class="expandMore" @click="revealed = true">
        mdi-chevron-right
      </VIcon>
    </VCard>
  </VSlideItem>
</template>

<script>
import eventBus from '@/plugins/eventBus';

export default {
  name: 'TheHomeWeddingsItem',

  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: null,
    },
    imageName: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    commentOverflow: false,
    revealed: false,
  }),

  mounted() {
    this.commentOverflow = this.$refs.commentContainer.scrollHeight > 190;

    if (this.commentOverflow) {
      eventBus.$on('closeWeddingItems', this.hideExpandMore);
    }
  },

  beforeDestroy() {
    if (this.commentOverflow) {
      eventBus.$off('closeWeddingItems', this.hideExpandMore);
    }
  },

  methods: {
    hideExpandMore() {
      this.revealed = false;
    },
  },
};
</script>

<style scopes>
.comment {
  position: relative;
  max-height: 198px;
  overflow: hidden;
}

.revealed {
  overflow: visible;
  max-height: initial;
}

.fadeLayer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
}

.expandMore {
  position: absolute !important;
  bottom: -8px;
  right: 50%;
  transform: translateX(50%) rotate(90deg);
}
</style>
