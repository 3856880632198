import 'core-js/stable';
import smoothscroll from 'smoothscroll-polyfill';
import VueSplide from '@splidejs/vue-splide';
import './assets/css/splide-default.min.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins/sentry';
import './plugins/meta';
import './plugins/analytics';

smoothscroll.polyfill();

Vue.config.productionTip = false;

Vue.use(VueSplide);

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
