<template>
  <div
    class="pa-4"
    style="margin-top: 100px;"
  >
    <h1 class="mb-3">
      Adatvédelmi nyilatkozat
    </h1>

    <p>
      Dunai János e.v., a dunaijanos.com tulajdonosa kiemelt figyelmet
      fordít a látogatók adatainak biztonságos kezelésére.
      Cégünk vállalja, hogy minden esetben a hatályos hazai törvények
      szerint jár el az adatkezelésben (jelenleg az információs önrendelkezési
      jogról és az információszabadságról szóló 2011. évi CXII. törvény).
      Az adatokat bizalmasan kezeljük, harmadik félnek nem továbbítjuk azokat.
      A weboldal használatával Ön hozzájárul, hogy az így keletkezett
      adatokat felhasználjuk az oldal üzemeltetéséhez,
      fejlesztéséhez, promóciós- és egyéb marketingtevékenységekhez.<br>
      Dunai János e.v. fenntartja a jogot jelen adatvédelmi nyilatkozat megváltoztatására.
    </p>

    <p><b>Adatkezelő elérhetőségei:</b></p>

    <p>
      Dunai János e.v.<br>
      Cím: H-5661 Újkígyós Arany János u. 46.<br>
      Telefon: +36702809652<br>
      E-mail: vofely@dunaijanos.com
    </p>

    <p><b>COOKIE-K (SÜTIK):</b></p>

    <p>
      A sütik segítségével információk gyűjthetők a dunaijanos.com és aloldalainak
      (továbbiakban: dunaijanos.com) látogatóiról és eszközeikről; megjegyzik a
      látogatók egyéni beállításait, amelyek felhasználásra kerül(het)nek.
      Ezen sütik lejáratukig a látogató böngészésre használt eszközén, annak
      böngészőjében maradnak, illetve amíg a látogató nem törli őket.
      Feltétlenül szükséges cookie-k: Ezen sütik célja, hogy a látogatók maradéktalanul
      és zökkenőmentesen böngészhessék a dunaijanos.com-t, használhassák annak funkcióit,
      és az ott elérhető szolgáltatásokat. Harmadik féltől származó cookie-k:
    </p>

    <p>
      <ul>
        <li>
          Google Analytics: statisztikai célú szolgáltatás használatával információkat
          gyűjt azzal kapcsolatban, hogy a látogatók hogyan használják a dunaijanos.com-t.
          Az adatot a honlap fejlesztésének és a felhasználói élmény javításának
          céljával használja fel. Honlapunk aktiváltatta az IP-címek anonimizálását,
          így az Analytics az Adatgyűjtő Hálózat lehető legkorábbi szakaszában
          anonimmá teszi a címet. Ezen sütik működésére a Google adatvédelmi
          irányelvei vonatkoznak
          ( <a
            href="https://support.google.com/analytics/answer/6004245?hl=hu"
            target="_blank"
          >
            megtekintés
          </a> ).
        </li>
      </ul>
    </p>

    <p><b>Jogorvoslati lehetőséggel a következő hatóságnál lehet élni:</b></p>

    <p>
      Nemzeti Adatvédelmi és Információszabadság Hatóság<br>
      Cím: H-1125 Budapest, Szilágyi Erzsébet fasor 22/c<br>
      Levelezési cím: H-1530 Budapest, Pf.: 5<br>
      Telefon: +36 1 391 1400<br>
      Fax: +36 1 391 1410<br>
      E-mail: ugyfelszolgalat@naih.hu
    </p>

    <p>
      A weboldal használatával a felhasználó elfogadja jelen adatvédelmi nyilatkozatot.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyStatement',
  metaInfo: {
    title: 'Dunai János vőfély | Adatvédelmi nyilatkozat',
  },
};
</script>

<style scoped>
</style>
