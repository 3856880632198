<template>
  <VApp>
    <ThePageHeader />

    <VContent class="pa-0 container">
      <VContainer
        fluid
        class="pa-0"
      >
        <transition
          appear
          name="appear"
          mode="out-in"
        >
          <router-view />
        </transition>
      </VContainer>
    </VContent>

    <ThePageFooter />

    <TheCookieBar />

    <ThePageFbChat />
  </VApp>
</template>

<script>
import ThePageHeader from '@/components/ThePageHeader.vue';
import ThePageFooter from '@/components/ThePageFooter.vue';
import TheCookieBar from '@/components/TheCookieBar.vue';
import ThePageFbChat from '@/components/ThePageFbChat.vue';

export default {
  name: 'App',
  components: {
    ThePageHeader,
    ThePageFooter,
    TheCookieBar,
    ThePageFbChat,
  },
};
</script>

<style lang="scss">
img {
  user-select: none;
}

a {
  text-decoration: none;
}

.fontDancingScript {
  font-family: 'Dancing Script', cursive !important;
}

.v-application.theme--light {
  background: #f4f2ef !important;
}

.appear-enter-active,
.appear-leave-active {
  transition: opacity 0.5s;
}
.appear-enter,
.appear-leave-to {
  opacity: 0;
}

.container {
  max-width: 1920px;
  margin: auto;
  overflow: hidden;
  min-height: 100vh;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .container {
    width: calc(100vw - 17px);
  }
}

.blueimp-gallery-display-fix {
  opacity: 1 !important;
}
</style>
