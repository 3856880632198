<template>
  <VContainer class="pa-0 mt-3">
    <VRow
      id="tradicionalis-vofely"
      no-gutters
    >
      <VCol
        cols="12"
        md="4"
        style="position: relative;"
      >
        <VImg
          :src="require('@/assets/home-traditional-bridesman.jpg')"
          contain
          eager
        />

        <div class="imageText secondary--text display-1 font-weight-bold">
          Tradicionális<br>vőfély

          <VImg
            :src="require('@/assets/wedding-rings-gold.svg')"
            contain
            eager
            width="100px"
            class="mx-auto mt-10"
          />
        </div>
      </VCol>

      <VCol
        cols="12"
        md="8"
        class="pa-8 mt-12"
        style="position: relative;"
      >
        <div class="text-center display-3 mb-12">
          <span class="fontDancingScript">Tradicionális vőfély</span>
        </div>

        <div class="subtitle-1">
          <p>
            Ismerjetek meg, mint hagyománykövető vőfély, hiszen hálával tartozom a sorsnak, hogy
            kitanulhattam a vőfélyek tradicionális mesterségét. Mentorom,
            Hanyecz László minden tudását örökül hagyta nekem. Hittel és büszkeséggel,
            a lakodalom végéig viselem hagyományörző ruhámon,
            szívem felett a kitűzőt.
          </p>

          <p>
            A lakodalmak évtizedes szokásait tovább éltetve, tradicionális vőfélyként
            minden fontos eseményt versekkel, rigmusokkal kísérve irányítom az esküvőtöket.
          </p>

          <p>
            Amennyiben német ajkú vendégeitek is vannak, akkor hozzájuk
            anyanyelvükön szólok, őket németül tájékoztatom az eseményekről.
          </p>
        </div>

        <VImg
          :src="require('@/assets/wedding-rings-bronze.svg')"
          contain
          eager
          width="150px"
          class="separator"
        />
      </VCol>
    </VRow>

    <VRow
      id="modern-vofely"
      no-gutters
      class="mt-3"
    >
      <VCol
        cols="12"
        md="4"
        style="position: relative;"
      >
        <VImg
          :src="require('@/assets/home-modern-bridesman.jpg')"
          contain
          eager
        />

        <div class="imageText secondary--text display-1 font-weight-bold">
          Innovatív<br>vőfély

          <VImg
            :src="require('@/assets/wedding-rings-gold.svg')"
            contain
            eager
            width="100px"
            class="mx-auto mt-10"
          />
        </div>
      </VCol>

      <VCol
        cols="12"
        md="8"
        class="pa-8 mt-12"
        style="position: relative;"
      >
        <div class="text-center display-3 mb-12">
          <span class="fontDancingScript">Innovatív vőfély</span>
        </div>

        <div class="subtitle-1">
          <p>
            Kétféle megjelenésem alapvetően különbözik, ámde minőségében azonos.
            A változó igények és lehetőségek magukban hordozzák az újat,
            a valami mást, a megszokottól eltérőt. Ez pedig a lakodalmakban
            is megjelenik. Elegáns viseletemben, rövidebb versekkel, újszerű szóhasználattal a
            mai kor elvárásainak is megfelelek.
          </p>

          <p>
            A modern lakodalmak ceremóniáit is mesterien kísérem végig, nem okoz gondot, ha a
            hagyományostól eltérő igényeitek vannak.
          </p>

          <p>
            Természetesen német vendégeiteket sem felejtem, őket anyanyelvükön tájékoztatom.
          </p>
        </div>

        <VImg
          :src="require('@/assets/wedding-rings-bronze.svg')"
          contain
          eager
          width="150px"
          class="separator2"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'TheHomeBridesman',
};
</script>

<style scoped>
.imageText {
    text-align: center;
    position: absolute;
    z-index: 2;
    top: 67%;
    transform: translateY(-50%);
    width: 100%;
    text-transform: uppercase;
}

.separator {
  position: absolute;
  bottom: 7vw;
  left: 50%;
  transform: translateX(-50%);
}
.separator2 {
  position: absolute;
  bottom: 8vw;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1300px) {
  .separator {
    display: none;
  }

  .separator2 {
    display: none;
  }
}
</style>
