<template>
  <VBtn
    color="white"
    fab
    fixed
    bottom
    left
    class="ml-4 mb-4"
    href="https://open.spotify.com/show/31F2y4mcb82OmGrJ2whhOA?si=ec3b0bc109ea440b"
    target="_blank"
  >
    <VImg
      :src="require('@/assets/podcast.svg')"
      contain
      eager
      width="50"
      height="50"
      class="mx-auto"
    />
  </VBtn>
</template>

<script>
export default {
  name: 'TheHomePodcastFab',
};
</script>

<style scoped>
</style>
