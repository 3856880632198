import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import hu from 'vuetify/es5/locale/hu';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#795548',
        secondary: '#fff59d',
        tertiary: '#232323',
      },
    },
  },
  lang: {
    locales: { hu },
    current: 'hu',
  },
});
