<template>
  <div id="kapcsolat" class="pt-12 mb-12">
    <div class="text-center display-3 mt-12">
      <span class="fontDancingScript"> Kapcsolat </span>
    </div>

    <VRow align="center" justify="center" class="mt-8">
      <VCol class="text-center" cols="12" md="5">
        <div class="headline mb-5">Dunai János - vőfély</div>

        <div class="headline font-weight-bold mb-5">
          <a href="tel:+36702809652" class="black--text"> +36 70 280 9652 </a>
        </div>

        <div class="headline font-weight-bold mb-5">
          <a href="mailto:vofely@dunaijanos.com" class="black--text"> vofely@dunaijanos.com </a>
        </div>

        <div class="title mb-5">
          Dunai János e.v.<br />
          Újkígyós<br />
          55658957-1-24
        </div>

        <div>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/dunaijanos-website-production.appspot.com/o/2022_dunai_janos_szoroanyag_web.pdf?alt=media&token=3f124645-ef16-4237-88e5-286a5f9e2436"
            target="_blank"
          >
            <VBtn outlined large width="248px" color="primary" style="border-width: 3px">
              <span class="title font-weight-bold black--text">Letölthető anyag</span>
            </VBtn>
          </a>
        </div>

        <div class="mt-5">
          <a href="https://www.nuptia.hu/2022/06/02/dunai-janos" target="_blank">
            <VBtn outlined large width="248px" color="#98b367" style="border-width: 3px">
              <span class="title font-weight-bold" style="color: #98b367"> a nuptia.hu írta </span>
            </VBtn>
          </a>
        </div>
      </VCol>

      <VCol class="text-center" cols="12" md="2">
        <VImg
          :src="require('@/assets/wedding-rings-bronze.svg')"
          contain
          eager
          width="120px"
          class="mx-auto"
        />
      </VCol>

      <VCol class="text-center px-5" cols="12" md="5">
        <div class="headline font-weight-bold">Ajánlott szolgáltatók</div>

        <a href="https://furhenrik.hu/" target="_blank" class="tertiary--text">
          <div class="recommendedServiceProvider">Für Henrik fotográfus | furhenrik.hu</div>
        </a>

        <a href="https://arcanumhotel.hu/" target="_blank" class="tertiary--text">
          <div class="recommendedServiceProvider">Arcanum Hotel****</div>
        </a>

        <a href="https://inspiredmedia.hu/" target="_blank" class="tertiary--text">
          <div class="recommendedServiceProvider">Inspiredmedia.hu | videó</div>
        </a>

        <a href="https://www.takacszsolt.hu/" target="_blank" class="tertiary--text">
          <div class="recommendedServiceProvider">Takács Zsolt fényképész</div>
        </a>

        <a href="http://www.silverzenekar.hu/" target="_blank" class="tertiary--text">
          <div class="recommendedServiceProvider">Silver Zenekar</div>
        </a>

        <a href="https://apetit.hu/" target="_blank" class="tertiary--text">
          <div class="recommendedServiceProvider">aPetit Catering - Békéscsaba</div>
        </a>
      </VCol>
    </VRow>
  </div>
</template>

<script>
export default {
  name: 'TheHomeContact',
};
</script>

<style scoped>
.recommendedServiceProvider {
  border: 3px solid #795548;
  border-radius: 4px;
  margin: 10px auto 10px auto;
  max-width: 400px;
  font-size: 18px;
}
</style>
