<template>
  <VSnackbar
    v-model="open"
    :timeout="20000"
    bottom
    left
  >
    <span class="caption">
      Ez az oldal sütiket használ a felhasználói élmény fokozása érdekében.
      <RouterLink :to="{ name: 'PrivacyStatement' }">
        Részletek
      </RouterLink>
    </span>

    <VIcon
      color="white"
      class="closeButton"
      @click="dismissCookieVar"
    >
      mdi-close
    </VIcon>
  </VSnackbar>
</template>

<script>
export default {
  name: 'TheCookieBar',
  data() {
    return {
      open: !document.cookie.includes('dismissedCookieBar'),
    };
  },
  methods: {
    dismissCookieVar() {
      document.cookie = 'dismissedCookieBar=true; expires=Thu, 18 Dec 2200 12:00:00 UTC';

      this.open = false;
    },
  },
};
</script>

<style scoped>
a {
  color: white;
}

.closeButton {
  margin-left: 10px;
  margin-right: -15px;
}
</style>
