<template>
  <VParallax
    :src="require('@/assets/home-slogan-parallax.jpg')"
    height="600"
  >
    <VRow
      align="center"
      justify="center"
    >
      <VCol
        class="text text-center display-3"
        style="line-height: 1.5;"
        cols="12"
      >
        <span class="text fontDancingScript">
          Egy lakodalomban egyetlen kötelező elem van: a két igen.<br>
          Minden más formálható.
        </span>
      </VCol>
    </VRow>
  </VParallax>
</template>

<script>
export default {
  name: 'TheHomeSlogan',
};
</script>

<style scoped>
@media (max-width: 500px) {
  .text {
    font-size: 40px !important;
    line-height: 1 !important;
  }
}
</style>
