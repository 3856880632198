import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from '../router';

Vue.use(VueAnalytics, {
  id: 'UA-156495992-1',
  router,
  set: [{ field: 'anonymizeIp', value: true }],
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production',
  },
});
