<template>
  <VRow
    align="center"
    justify="center"
    class="mt-8"
  >
    <VCol
      class="text-center"
      cols="12"
      md="2"
    >
      <VImg
        :src="require('@/assets/wedding-rings-bronze.svg')"
        contain
        eager
        width="120px"
        class="mx-auto"
      />
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'TheRingSeperator',
};
</script>
