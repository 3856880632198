import { render, staticRenderFns } from "./ThePageFooter.vue?vue&type=template&id=2627b987&scoped=true"
import script from "./ThePageFooter.vue?vue&type=script&lang=js"
export * from "./ThePageFooter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2627b987",
  null
  
)

export default component.exports