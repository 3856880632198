<template>
  <div>
    <TheHomeBanner />

    <TheHomeBridesman />

    <TheHomeArguments />

    <TheHomeWeddings />

    <TheHomeVideos />

    <TheRingSeperator />

    <TheHomeGallery />

    <TheHomeSlogan />

    <TheHomeContact />

    <TheHomePodcastFab />
  </div>
</template>

<script>
import TheHomeBanner from '@/components/TheHomeBanner.vue';
import TheHomeBridesman from '@/components/TheHomeBridesman.vue';
import TheHomeArguments from '@/components/TheHomeArguments.vue';
import TheHomeWeddings from '@/components/TheHomeWeddings.vue';
import TheHomeVideos from '@/components/TheHomeVideos.vue';
import TheHomeGallery from '@/components/TheHomeGallery.vue';
import TheHomeSlogan from '@/components/TheHomeSlogan.vue';
import TheHomeContact from '@/components/TheHomeContact.vue';
import TheRingSeperator from '@/components/TheRingSeperator.vue';
import TheHomePodcastFab from '@/components/TheHomePodcastFab.vue';

export default {
  name: 'Home',
  components: {
    TheHomeBanner,
    TheHomeBridesman,
    TheHomeArguments,
    TheHomeWeddings,
    TheHomeVideos,
    TheRingSeperator,
    TheHomeGallery,
    TheHomeSlogan,
    TheHomeContact,
    TheHomePodcastFab,
  },
  metaInfo: {
    title: 'Dunai János vőfély',
    meta: [
      {
        name: 'description',
        content:
          'Tradicionális vőfély, vagy innovatív vőfély? Egy lakodalomban egyetlen kötelező elem van: a két igen. Minden más formálható.',
      },
    ],
  },
};
</script>
