<template>
  <div class="mx-5">
    <VImg
      :src="require(`@/assets/${imageName}`)"
      contain
      eager
      width="80px"
      class="mx-auto mb-8 mt-12"
    />

    <div
      class="font-weight-bold mx-auto title-size"
      style="width: 240px; height: 64px;"
    >
      {{ title }}
    </div>

    <VTooltip
      bottom
      max-width="240px"
      open-delay="0"
      close-delay="0"
      color="#efeff4"
    >
      <template v-slot:activator="{ on }">
        <div
          class="infoIcon"
          v-on="on"
        />
      </template>

      <div class="tooltipText">
        <slot name="tooltip" />
      </div>
    </VTooltip>
  </div>
</template>

<script>
export default {
  name: 'TheHomeArgumentsItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    imageName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scopes>
.infoIcon {
  background-image: url('../assets/i-default.svg');
  background-size: cover;
  width: 30px;
  height: 30px;
  margin: auto;
}

.infoIcon:hover {
  background-image: url('../assets/i-hover.svg');
}

.tooltipText {
  text-align: center;
  color: #4f4f52;
  font-size: 12px;
  line-height: 2;
  font-weight: 600;
}

.title-size {
  font-size: 1.15rem !important;
}
</style>
