<template>
  <div class="bannerContainer">
    <VImg
      :src="require('@/assets/home-banner-background.jpg')"
      contain
      eager
      width="100%"
    />

    <VImg
      :src="require('@/assets/logo.svg')"
      contain
      eager
      class="logo"
    />

    <router-link
      to="#tradicionalis-vofely"
      class="hidden-sm-and-down"
    >
      <div class="iconScroll" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'TheHomebannder',
};
</script>

<style scoped>
.bannerContainer {
  position: relative;
}

@media (max-width: 960px) {
  .bannerContainer {
    margin-top: 94px;
  }
}

.logo {
  width: 550px;
  max-width: 70vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.iconScroll,
.iconScroll:before {
  position: absolute;
  left: 50%;
}

.iconScroll {
  width: 40px;
  height: 70px;
  margin-left: -20px;
  top: 65%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
}

.iconScroll:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
</style>
