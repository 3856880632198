import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import PrivacyStatement from '../views/PrivacyStatement.vue';
import LegalNotice from '../views/LegalNotice.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/adatvedelmi-nyilatkozat',
    name: 'PrivacyStatement',
    component: PrivacyStatement,
  },
  {
    path: '/jogi-nyilatkozat',
    name: 'LegalNotice',
    component: LegalNotice,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '*',
    name: '404',
    redirect: { name: 'Home' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      document.getElementById(to.hash.replace('#', '')).scrollIntoView({ behavior: 'smooth' });
      return null;
    }

    return window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  },
  routes,
});

export default router;
