<template>
  <VAppBar
    flat
    fixed
    hide-on-scroll
    height="94px"
    style="width: 1920px; max-width: 100vw;"
    class="mx-auto"
  >
    <VToolbarTitle>
      <RouterLink :to="{ name: 'Home' }">
        <VImg
          :src="require('@/assets/logo.svg')"
          contain
          eager
          height="55"
          @click="scrollTop"
        />
      </RouterLink>
    </VToolbarTitle>

    <VSpacer />

    <VToolbarItems>
      <VBtn
        text
        to="/#tradicionalis-vofely"
        class="hidden-sm-and-down font-weight-bold"
      >
        Tradicionális vőfély
      </VBtn>

      <VBtn
        text
        to="/#modern-vofely"
        class="hidden-sm-and-down font-weight-bold"
      >
        Innovatív vőfély
      </VBtn>

      <VBtn
        text
        to="/#rolam-mondtak"
        class="hidden-sm-and-down font-weight-bold"
      >
        Rólam mondták
      </VBtn>

      <VBtn
        text
        to="/#kapcsolat"
        class="hidden-sm-and-down font-weight-bold"
      >
        Kapcsolat
      </VBtn>

      <VImg
        :src="require('@/assets/page-header-dots.svg')"
        contain
        eager
        width="35"
        class="mx-4"
        style="cursor: pointer;"
        @click="showSheet = true"
      />
    </VToolbarItems>

    <VBottomSheet
      v-model="showSheet"
      fullscreen
    >
      <div
        class="d-flex align-center justify-center"
        style="height: 100%; background: white;"
      >
        <VList
          flat
          class="text-center"
          width="100%"
        >
          <VListItem
            :ripple="false"
            class="mb-6"
            @click="showSheet = false"
          >
            <VListItemTitle>
              <VImg
                :src="require('@/assets/page-header-close-sheet.svg')"
                contain
                eager
                width="50"
                height="50"
                class="mx-auto"
              />
            </VListItemTitle>
          </VListItem>

          <VListItem to="/#tradicionalis-vofely">
            <VListItemTitle class="headline font-weight-bold">
              Tradicionális vőfély
            </VListItemTitle>
          </VListItem>

          <VListItem to="/#modern-vofely">
            <VListItemTitle class="headline font-weight-bold">
              Innovatív vőfély
            </VListItemTitle>
          </VListItem>

          <VListItem to="/#rolam-mondtak">
            <VListItemTitle class="headline font-weight-bold">
              Rólam mondták
            </VListItemTitle>
          </VListItem>

          <VListItem to="/#videoim">
            <VListItemTitle class="headline font-weight-bold">
              Videóim
            </VListItemTitle>
          </VListItem>

          <VListItem to="/#galeria">
            <VListItemTitle class="headline font-weight-bold">
              Galéria
            </VListItemTitle>
          </VListItem>

          <VListItem to="/#kapcsolat">
            <VListItemTitle class="headline font-weight-bold primary--text">
              Kapcsolat
            </VListItemTitle>
          </VListItem>
        </VList>
      </div>
    </VBottomSheet>
  </VAppBar>
</template>

<script>
export default {
  name: 'ThePageHeader',
  data() {
    return {
      showSheet: false,
    };
  },
  watch: {
    '$route.hash': {
      handler() {
        this.showSheet = false;
      },
    },
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
</style>
