<template>
  <div
    class="pa-4"
    style="margin-top: 100px;"
  >
    <h1 class="mb-3">
      Jogi nyilatkozat
    </h1>

    <div>
      <h3 class="mb-2">
        A dunaijanos.com üzemeltetője:
      </h3>

      <p>
        Dunai János e.v.<br>
        Cím: H-5661 Újkígyós Arany János u. 46.<br>
        Telefon: +36702809652<br>
        E-mail: vofely@dunaijanos.com
      </p>

      <p>
        A dunaijanos.com-on, illetve aldomainjein található információk minden esetben tájékoztató,
        illetve illusztrációs jellegűek, eltérhetnek a valóságtól. A felmerülő hibákért,
        tévedésekért, elavult információkért nem vállalunk felelősséget.
        Dunai János e.v. fenntartja a jogot az oldal figyelmeztetés nélküli módosítására,
        törlésére. Dunai János e.v. nem vállal felelősséget a dunaijanos.com-on található
        linkeken keresztül elérhető bármely külső tartalomért.
      </p>

      <p>
        A dunaijanos.com kereskedelmi, illetve egyéb üzleti célú mentése, sokszorosítása,
        illetve terjesztése szigorúan tilos. A dunaijanos.com-on található képi és
        szöveges tartalmak szerzői jogvédelem alatt állnak.
      </p>

      <p>
        A dunaijanos.com látogatója a weboldal használatával a fenti feltételeket elfogadja.
      </p>

      <p>
        Észrevételeket a vofely@dunaijanos.com címen fogadunk.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegalNotice',
  metaInfo: {
    title: 'Dunai János vőfély | Jogi nyilatkozat',
  },
};
</script>

<style scoped>
</style>
